import React from 'react'
import classes from './Job.module.css'

const Psychologue = (props) => {
    return(
        <div>
            <div>

            </div>
            <div>
                <JobBody/>
            </div>
            <div style={{padding: '0 24px', marginBottom: 60}}>
                <h3><b>Postuler</b></h3>
                <p>Nous nous réjouissons de recevoir votre candidature à l’adresse suivante: <a href="mailto:emploi@psy-scan.ch">emploi@psy-scan.ch</a></p>
                <p>Dans la mesure où toutes nos candidatures sont traitées de manière électronique, nous vous remercions de postuler exclusivement par internet (<a href="mailto:emploi@psy-scan.ch">emploi@psy-scan.ch</a>), en nous adressant, au minimum, un curriculum vitae accompagné d'une lettre de motivation.</p>

            </div>
        </div>
    )
}

const JobBody = () => (
    <div style={{padding: '24px 16px 24px'}} className={classes.contain}>
        <h1><b>Psychologue – psychothérapeute</b></h1>
        <p>Veuillez noter que ceci est une offre d’emploi pour des psychologues expérimenté(e)s. Nous ne proposons pas d’expérience de travail ni de stage.</p>
        <div className={classes.jobHighlights}>
            <p><b>Établissement :</b> Neuchâtel</p>
            <p><b>Taux d’occupation :</b> 50 – 100%</p>
            <p><b>Durée de poste :</b> Poste de durée indéterminée</p>
            <p><b>Type d'emploie :</b> Temps plein, Temps partiel</p>
            <p><b>Date d’entrée en fonction :</b> De suite ou à convenir</p>
        </div>
        <h3><b>Contexte</b></h3>
        <p>Depuis 2003, le Psy-San Institute est très actif dans le secteur psychothérapeutique en Suisse romande et nous aidons des personnes de tout âge à surmonter leurs difficultés et à s’épanouir dans leur vie personnelle et professionnelle avec nos services de psychothérapie.</p>
        <p>Nos praticiens sont dévoués à leurs clients/patients et sont fiers d’améliorer leur bien-être émotionnel. Ils sont engagés dans leur propre développement professionnel et y parviennent par le biais d’études continues et de supervision clinique.</p>
        <p>Si vous souhaitez joindre notre équipe, vous êtes motivé à nous aider à élever le niveau de traitement dans le secteur privé en Suisse et vous répondez aux critères ci-dessous, nous vous invitons chaleureusement à postuler.</p>
        
        <h3><b>Votre Mission</b></h3>
        <ul className={classes.listPoint}>
            <li>Assurer le suivi psychothérapeutique d’une patientèle en ambulatoire</li>
            <li>Possibilité de pratiquer des suivis individuels, de famille et de groupe</li>
            <li>Participer aux colloques cliniques hebdomadaires et aux colloques généraux thématiques</li>
            <li>Participer au développement et à la mise en œuvre des programmes de soins élaborés au sein de l’institut</li>
        </ul>

        <h3><b>Profile</b></h3>
        <ul className={classes.listPoint}>
            <li>Vous êtes titulaire d'une licence et un diplôme en psychologie, Bachelor et Master</li>
            <li>Vous possédez une compétence dans la conduite d’entretiens</li>
            <li>Vous avez une capacité à travailler de façon autonome et vous êtes également capable de travailler au sein d’une équipe</li>
            <li>Vous avez du dynamisme, de la flexibilité et une bonne gestion du stress</li>
            <li>Vous avez une capacité à vous adapter à de nouvelles situations</li>
            <li>Vous disposez d'une expérience de travail de quelques années dans le domaine.</li>
            <li>Vous maîtrisez la rédaction des rapports et des anamnèses.</li>
        </ul>
        


    </div>
)

export default Psychologue