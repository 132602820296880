import React, { Component } from 'react'
import {KeyboardArrowRight} from "../../components/icons";
import classes from '../../pageStyles/carrieres.module.css'
import Footer from "../../components/Footer/Footer";
import JobView from "../../components/Hire/JobView";
import StayTuned from "../../components/Hire/StayTuned";
import Layout from '../../layouts';


 class CareerPage extends Component {
    state = {
        jobView: false,
        selectedJobId: ''
    }

    handleCloseJobView = () => this.setState({jobView: false}, () =>{
        if(window) {
            window.document.querySelector('html').style.overflow = 'initial'
        }
    })

    handleOpenJobView = value => {
        console.log('value', value)
        if(window) {
            window.document.querySelector('html').style.overflow = 'hidden'
        }
        this.setState({jobView: true, selectedJobId: value})
    }

    render() {

        return(
            <Layout location={this.props.location}>
                <div style={{background:'#F4F4F4'}}>

                    <div className={classes.CareerPageHeader}>
                        <div className="pageContainer cPageHeader">
                            <h1>Une carrière avec le Psy-Scan Institute (PSI)</h1>
                            <p>Chez le PSI, chaque journée de travail aura une influence marquante. Vous pourrez vous épanouir grâce à une culture dynamique, d'inclusion
                            et de flexibility.</p>
                            <p>Ici, nous develeppons nos membres afin qu'ils donnent le meilleur d'eux-mêmes et puissent avoir un effet positif non seulement sur les patients,
                            leurs collègues et la communauté, mais aussi sur leur propre carrière et leurs aspirations.</p>
                            <h2>Quelle influence aurez-vous?</h2>
                        </div>
                    </div>
                    <div style={{background:'white', marginBottom: 32}}>
                        <div className="pageContainer" style={{maxWidth:720}}>
                            <h2 style={{textAlign:'center', marginBottom: 40}}>Nos Opportunités</h2>
                            <div>
                                <JobItem id="001" title="Psychiatre" n={1} openJobView={this.handleOpenJobView}/>
                                <JobItem id="002" title="Psychologue" n={1} openJobView={this.handleOpenJobView}/>
                                <JobItem id="003" title="Secrétaire médicale" n={1} openJobView={this.handleOpenJobView}/>
                            </div>

                        </div>
                    </div>
                    <StayTuned />
                    {this.state.jobView && <JobView closeJobView={this.handleCloseJobView} {...this.state}/>}
                    <Footer/>
                    <style>{`
                        .cPageHeader {
                            font-size: 1.1rem;
                        }
                        
                        @media (min-width: 767px) {
                            .cPageHeader {
                                font-size: 1.2rem;
                            }
                        }
                    `}</style>
                    </div>
            </Layout>
        )
    }
}

const JobItem = ({title, n, openJobView, id }) => {
    return(
        <div style={{
            display:'flex',
            position:'relative',
            justifyContent:'space-between',
            padding: '16px 32px 16px 16px',
            border:'1px solid #cecece',
            borderRadius:3,
            cursor:'pointer',
            marginBottom:16
        }} className={classes.JobItem} onClick={() => openJobView(id)}>
            <div style={{fontSize: '1.1rem'}}>{title}</div>
            <div className={classes.JobItemPosition}>{n} position</div>
            <div style={{position:'absolute', right:0, top: 0, height:'100%', display:'flex', alignItems:'center'}}>
                <KeyboardArrowRight fill="#999" width="32" height="32"/>
            </div>
        </div>
    )
}

export default CareerPage