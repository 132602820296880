import React from 'react'
import classes from './Job.module.css'

const Secretaire = (props) => {
    return(
        <div>
            <div>

            </div>
            <div>
                <JobBody/>
            </div>
            <div style={{padding: '0 24px', marginBottom: 60}}>
                <h3><b>Postuler</b></h3>
                <p>Nous nous réjouissons de recevoir votre candidature à l’adresse suivante: <a href="mailto:emploi@psy-scan.ch">emploi@psy-scan.ch</a></p>
                <p>Dans la mesure où toutes nos candidatures sont traitées de manière électronique, nous vous remercions de postuler exclusivement par internet (<a href="mailto:emploi@psy-scan.ch">emploi@psy-scan.ch</a>), en nous adressant, au minimum, un curriculum vitae accompagné d'une lettre de motivation.</p>
            </div>
        </div>
    )
}

const JobBody = () => (
    <div style={{padding: '24px 16px 24px'}} className={classes.contain}>
        <h1><b>Secrétaire Médical(e)</b></h1>
        <div className={classes.jobHighlights}>
            <p><b>Établissement :</b> Neuchâtel</p>
            <p><b>Taux d’occupation :</b> 50 – 100%</p>
            <p><b>Durée de poste :</b> Poste de durée indéterminée</p>
            <p><b>Type d'emploie :</b> Temps plein, Temps partiel</p>
            <p><b>Date d’entrée en fonction :</b> De suite ou à convenir</p>
        </div>
        <h3><b>Contexte</b></h3>
        <p>Depuis 2003, le Psy-San Institute est très actif dans le secteur psychothérapeutique en Suisse romande et nous aidons des personnes de tout âge à surmonter leurs difficultés et à s’épanouir dans leur vie personnelle et professionnelle avec nos services de psychothérapie.</p>
        <p>Nos praticiens sont dévoués à leurs clients/patients et sont fiers d’améliorer leur bien-être émotionnel. Ils sont engagés dans leur propre développement professionnel et y parviennent par le biais d’études continues et de supervision clinique.</p>
        <p>Si vous souhaitez joindre notre équipe, vous êtes motivé à nous aider à élever le niveau de traitement dans le secteur privé en Suisse et vous répondez aux critères ci-dessous, nous vous invitons chaleureusement à postuler.</p>

        <h3><b>Votre Mission</b></h3>
        <ul className={classes.listPoint}>
            <li>Vous assurez l'accueil des patients, proches, visiteurs, dans le respect des principes directeurs de l'institution</li>
            <li>Vous assurez les tâches administratives en lien avec le secrétariat médical</li>
            <li>Vous assurez diverses tâches administratives liées au bon fonctionnement de l'unité.</li>
            <li>Vous rédigez et relisez les documents médicaux traités au sein du Service rapports & rédaction (rapports médicaux, certificats médicaux et divers courriers)</li>
            <li>Vous participez à la gestion du courrier, entrant et sortant, relatif notamment aux demandes des assurances ou des établissements spécialisés.</li>
        </ul>

        <h3><b>Profile</b></h3>
        <ul className={classes.listPoint}>
            <li>Vous êtes titulaire d'un CFC d'employé-e de commerce, ou formation jugée équivalente, idéalement complété d'un diplôme de secrétaire médical-e</li>
            <li>Vous bénéficiez d'une pratique, d'au minimum 5 ans, dans un secrétariat de manière autonome</li>
            <li>Vous maîtrisez parfaitement le français tant à l'oral qu'à l'écrit, connaissance d'une langue étrangère, un atout</li>
            <li>Vous avez une pratique avancée des outils informatiques de bureautique, connaissance des outils institutionnels, un atout</li>
            <li>Vous êtes capable d'intégrer une équipe pluridisciplinaire</li>
            <li>Vous savez adapter votre communication au contexte et faites preuve d'un esprit d'entraide et de collaboration</li>
            <li>Vous êtes doté-e d'une grande flexibilité, vous avez le sens de l'organisation et des priorités</li>
            <li>Vous faites preuve d'une grande capacité d'analyse et de synthèse.</li>
        </ul>


    </div>
)

export default Secretaire