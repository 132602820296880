import React, { Component } from 'react'
import Logo from '../../components/Logo/Logo'
import {CloseIcon} from "../icons";
import Psychiatre from "./Jobs/Psychiatre";
import Psychologue from "./Jobs/Psychologue";
import Secretaire from "./Jobs/Secretaire";
import classes from './jobView.module.css'


class JobView extends Component {
    state = {
        mounted: false,
        applyView: false
    }

    componentDidMount() {
        if(!this.state.mounted) {
            setTimeout(() => {
                this.setState({mounted: true})
            }, 10)
        }
    }

    innerCloseJobView = () => {
        this.setState({mounted: false}, () => {
            setTimeout(() => {
                this.props.closeJobView()
            }, 300)
        })
    }

    render() {
        let pos = `-100vh`
        if(this.state.mounted) {
            pos = 0
        }
        return(
            <div>
                <div className="jobView" style={{
                    width: "100vw",
                    height: "100vh",
                    position: "fixed",
                    bottom: `${pos}`,
                    left: 0,
                    transition: "0.35s all ease",
                    zIndex: 1000,

                }}>
                    <div className="viewContainer" style={{
                        width: "100vw",
                        height: "100vh",
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor: "white"
                    }}>
                        <div className="viewHeader" style={{
                            position: "absolute",
                            width: "100vw",
                            padding: 15,
                            margin: "auto",
                            background: "white",
                            zIndex: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: "0 1px 14px 0 rgba(0,0,0,0.09)"
                        }}>
                            <div>
                                <Logo />
                            </div>
                            <div style={{
                                padding: '8px 8px',
                                cursor:'pointer'
                            }} onClick={this.innerCloseJobView}>
                                <CloseIcon height="40" width="40"/>
                            </div>

                        </div>
                        <div className="viewBody" style={{
                            marginTop: 90,
                            marginLeft: "auto",
                            marginRight: "auto",
                            overflowY:"auto",
                            height: "calc(100vh - 90px)"
                        }}>
                            <div className={classes.JobViewHeader}>
                                <h3 style={{display:'flex', color: "#fff"}}><b>Carrières</b></h3>
                            </div>
                            <div style={{
                                maxWidth: 720,
                                margin: 'auto'
                            }}>
                                {this.props.selectedJobId === '001' && <Psychiatre />}
                                {this.props.selectedJobId === '002' && <Psychologue />}
                                {this.props.selectedJobId === '003' && <Secretaire />}

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default JobView