import React from 'react'
import Button from "../../Button/Button";
import classes from './Job.module.css'

const Psychiatre = (props) => {
    return(
        <div>
            <div>

            </div>
            <div>
                <JobBody/>
            </div>
            <div style={{padding: '0 24px', marginBottom: 60}}>
                <h3><b>Postuler</b></h3>
                <p>Nous nous réjouissons de recevoir votre candidature à l’adresse suivante: <a href="mailto:emploi@psy-scan.ch">emploi@psy-scan.ch</a></p>
                <p>Dans la mesure où toutes nos candidatures sont traitées de manière électronique, nous vous remercions de postuler exclusivement par internet (<a href="mailto:emploi@psy-scan.ch">emploi@psy-scan.ch</a>), en nous adressant, au minimum, un curriculum vitae accompagné d'une lettre de motivation.</p>
            </div>
        </div>
    )
}

const JobBody = () => (
    <div style={{padding: '24px 16px 24px'}} className={classes.contain}>
        <h1><b>Recherche d'un Psychiatre</b></h1>
        <div className={classes.jobHighlights}>
            <p><b>Établissement :</b> Neuchâtel</p>

            <p><b>Taux d’occupation :</b> 50 – 100%</p>

            <p><b>Type de poste :</b> Poste de durée indéterminée</p>

            <p><b>Date d’entrée en fonction :</b> De suite ou à convenir</p>
        </div>
        <h3><b>Contexte</b></h3>
        <p>Depuis 2003, le Psy-San Institute est très actif dans le secteur psychothérapeutique en Suisse romande et nous aidons des personnes de tout âge à surmonter leurs difficultés et à s’épanouir dans leur vie personnelle et professionnelle avec nos services de psychothérapie.</p>
        <p>Nos praticiens sont dévoués à leurs clients/patients et sont fiers d’améliorer leur bien-être émotionnel. Ils sont engagés dans leur propre développement professionnel et y parviennent par le biais d’études continues et de supervision clinique.</p>
        <p>Si vous souhaitez joindre notre équipe, vous êtes motivé à nous aider à élever le niveau de traitement dans le secteur privé en Suisse et vous répondez aux critères ci-dessous, nous vous invitons chaleureusement à postuler.</p>

        <h3><b>Votre Mission</b></h3>
        <ul className={classes.listPoint}>
            <li>Assurer le suivi psychiatrique et psychothérapeutique d’une patientèle en ambulatoire (suivis individuels, de famille et de groupe)</li>
            <li>Après avoir diagnostiqué la pathologie et les troubles psychiatriques des patients en suivie, proposer un traitement adapté et Assurer un suivi de qualité afin de constater les évolutions</li>
            <li>Participer aux colloques cliniques hebdomadaires et aux colloques généraux thématiques</li>
            <li>Participer au développement et à la mise en œuvre des programmes de soins élaborés au sein de l’institut.</li>
            <li>Assumer les activités de gestion administratives liées au poste</li>
            <li>Participer au système de garde du Centre Neuchâtelois de Psychiatrie.</li>

        </ul>

        <h3><b>Profile</b></h3>
        <ul className={classes.listPoint}>
            <li>Vous avez l’obtention d’un diplôme de médecin et spécialisation FMH en psychiatrie(avec la mention MEBEKO pour les titulaires d'un titre étranger), ou titre jugé équivalent.</li>
            <li>Vous bénéficiez d'au minimum deux années d’expérience en psychiatrie et une Maîtrise de la psychopharmacothérapie.</li>
            <li>Vous possédez des habiletés dans la conduite d’entretiens.</li>
            <li>Vous avez un diplôme universitaire en TCC ( CAS, DAS ou MAS)</li>
            <li>Vous savez comment travailler de manière autonome et aussi en équipe.</li>
            <li>Vous avez du dynamisme, de la flexibilité et une bonne gestion du stress.</li>
            <li>Vous avez une capacité à vous adapter à de nouvelles situations.</li>
            <li>Vous maîtrisez le français parfaitement tant que l’oral et l’écrit. Pour les médecins non francophones, vous avez une maîtrise du français niveau B2 au minimum (attestation requise).</li>
        </ul>
        


    </div>
)


export default Psychiatre