import React, { Component } from 'react'
import Input from "../Forms/Input";
import Button from "../Button/Button";
import axios from 'axios'

// const API_URL = `http://localhost:5001/psy-scan-1f213/us-central1/psyscan`
const API_URL = `https://us-central1-psy-scan-1f213.cloudfunctions.net/psyscan`

class StayTuned extends Component {
    state = {
        email: '',
        success: false,
        error: false,
        loading: false,
    }

    handleEmailChange = e => this.setState({email: e.target.value})

    handleEmailSubmit = () => {
        if(this.state.email.includes('@') && this.state.email.includes('.')) {
            this.setState({loading: true, error: false, success: false}, () => {
                axios.post(`${API_URL}/new-sub`, {email: this.state.email, type:'job'})
                    .then(response => {
                        if(!response.data.error) {
                            this.setState({success: true, error: false, loading: false, email: ''})
                        } else {
                            this.setState({success: false, error: true, loading: false})
                        }
                    })
                    .catch(() => {
                        this.setState({success: false, error: true, loading: false})
                    })
            })
        }


    }
    render() {
        return(
            <div style={{background:'#fff', marginBottom:32}}>
                <div className="pageContainer" style={{maxWidth:720, textAlign:'center'}}>

                    <h2 style={{textAlign:'center'}}>Restez Connecté</h2>
                    <p>Entrez votre adresse courriel et parlez-nous un peu de vous. Nous vous tiendrons informé(e) des opportunités qui correspondent à vos intérets.</p>
                    <p>
                        <Input styles={{height: 40, display: 'block'}} value={this.state.email} changed={this.handleEmailChange}/>
                        {this.state.success && <small style={{color: 'green', fontWeight: 500}}>Votre courriel a été ajouté avec success.</small>}
                        {this.state.error && <small style={{color: 'red', fontWeight: 500}}>Une error s'est produite, veuillez rafraîchir la page et réessayer.</small>}
                    </p>
                    <Button text="Continuer" color="primary" clicked={this.handleEmailSubmit} loading={this.state.loading}/>
                </div>
            </div>
        )
    }
}

export default StayTuned